import React from "react";
import Paper from '@mui/material/Paper';
//oprean@gmail.com & tEST123^
const Hero = () => (
  <div>
    The night is far spent, the day is at hand. 
  </div>
);

export default Hero;
